<template>
    <NavIntro />
    <IntroParte1 />
    <div class="container">
      <Grid />
      <Especialidades />
      <Cards />
      <PartnersSection />
      <Email/>
    </div>
</template>

<script>
import NavIntro from '@/components/NavIntro.vue';
import Email from '@/components/Footer.vue';
import IntroParte1 from '@/components/IntroParte1.vue';
import Cards from '@/components/Cards.vue';
import ShowText from '@/components/ShowText.vue';
import Grid from '@/components/Grid.vue';
import Especialidades from '@/components/Especialidades.vue';
import PartnersSection from '@/components/PartnersSection.vue';
 

export default {
  name: 'IntroView',
  components: {
    NavIntro,
    IntroParte1,
    Cards,
    ShowText,
    Grid,
    Especialidades,
    PartnersSection,
    Email,
  },
}
</script>

<style>

</style>