<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-green fixed-top">
    <a class="navbar-brand" href="#">
      <img src="img/logo.png" id="logo" class="logo-img">
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
      aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <!-- Sidebar -->
    <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
              aria-haspopup="true" aria-expanded="true">
              <span class="me-2 text-capitalize">{{user.name}}</span>
              <img src="img/user.jpg" alt="Avatar Logo" style="width:40px;" class="rounded-pill me-2">
              <i class="fas fa-angle-down text-secundary"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
              <button class="dropdown-item" @click="logout">Sair</button>
            </div>
          </li>
        </ul>
      </div>
  </nav>
</template>

<script>
  import axios from 'axios';

  export default {
      name: "Navbar",
      props: ["logo", "alt"],
      data() {
        return {
          user: {},
          servidor: 'https://api001.fazd.com.br',
        }
      },
      mounted() {
        this.getUserData();
        this.verifyUserLogin();
      },
      methods: {
        logout() {
          localStorage.removeItem('isLoggedIn');
          localStorage.removeItem('token');
          localStorage.removeItem('farm');
          // Redirecionar para a página de login
          this.$router.push('/login');
        },
        verifyUserLogin() {
        // Verificar se o usuário já está logado ao acessar a página "Home"
          if (!localStorage.getItem('isLoggedIn') || !localStorage.getItem('token')) {
            this.$router.push('/');
          } 
        },
        getUserData() {
          axios.get(this.servidor + '/api/user', {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
          })
          .then(response => {
            this.user = response.data;
            localStorage.setItem('farm', this.user.foreign_key_farm);
          })
          .catch(error => {
            console.error(error)
          });
        },
      },
      
  }
</script>

<style scoped>
  

</style>