<template>
  <div class="container-fluid py-4">
    <div class="row row-style">
      <div class="col-12">
        <form id="r-form" @submit.prevent="gerarRegistro">
          <div class="row">
            <div class="form-group col-md-8">
              <label for="raca-form">Raça</label>
              <input type="text" class="form-control" id="raca-form" v-model="formData.breed" placeholder="Digite uma raça (Ex: Holandes)" required>
            </div>
            <div class="form-group col-md-4">
                <label for="categoria">Tipo de Animal</label>
                <select class="form-control" id="categoria" v-model="formData.foreign_key_category" required>
                  <option value="" disabled>SELECIONE UM TIPO DE ANIMAL</option>
                  <option v-for="item in filteredList" :key="item.id" :value="item.id">{{ item.category.toUpperCase() }}</option>
                </select>
              </div>
            <div class="form-group col-12 py-3">
              <button type="submit" class="btn btn-green w-100">Salvar</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from 'axios';

export default {
  data() {
    return {
      list: [],
      formData: {
        breed: '',
        status: 1,
        foreign_key_category: '',
      },
    };
  },
  mounted() {
      this.getList()
  },
  computed: {
    filteredList() {
      return this.list.filter(listItem =>
        Object.values(listItem).some(value =>
          String(value)
        )
      );
    },
  },
  methods: {
    async checkExistingBreed(breed, categoryId) {
      try {
        const response = await axios.get(`https://api001.fazd.com.br/api/animals-breeds?foreign_key_category=${categoryId}`);
        const breeds = response.data;
        for (let i = 0; i < breeds.length; i++) {
          if (breeds[i].breed.toLowerCase() === breed.toLowerCase()) {
            return true;
          }
        }
        return false;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    async gerarRegistro() {
      try {
        const breedExists = await this.checkExistingBreed(this.formData.breed, this.formData.foreign_key_category);
        if (breedExists) {
          alert('A raça para este tipo de animal já existe. Por favor, insira uma raça diferente.');
        } else {
          const response = await axios.post('https://api001.fazd.com.br/api/animals-breeds', this.formData);
          console.log(response.data);
          this.$router.push('/dashboard-racas');
        }
      } catch (error) {
        console.error(error);
      }
    },
    getList() {
        axios.get('https://api001.fazd.com.br/api/animals-categories', {
            headers: {
              'Accept': 'application/json',
            },
        })
            .then(response => {
                this.list = response.data
            })
            .catch(error => {
                console.error(error)
            })
    },
  },
};
</script>
  
  <style scoped>
  .form-control {
    border-color: #ced4da;
  }
  
  .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
  }
  </style>