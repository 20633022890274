<template>
    <div class="container-fluid py-4">
      <div class="row row-style">
        <div class="col-12">
          <form id="users-form" @submit.prevent="gerarRegistro">
            <div class="row">
              <div class="form-group col-md-6">
                <label for="nome">Nome do Usuário</label>
                <input type="text" class="form-control" id="nome" v-model="formData.name" placeholder="Digite o nome do usuário">
              </div>
              <div class="form-group col-md-6">
                <label for="email">E-mail</label>
                <input type="email" class="form-control" id="email" v-model="formData.email" placeholder="Digite o e-mail do usuário">
              </div>
              <div class="form-group col-md-6">
                <label for="senha">Senha</label>
                <input type="password" class="form-control" id="senha" v-model="formData.password" placeholder="Digite uma senha">
              </div>
              <div class="form-group col-md-3">
                <label for="conta">Tipo de Conta</label>
                <select class="form-control" id="conta" v-model="formData.account_type">
                  <option value="" disabled>SELECIONE UM TIPO DE CONTA</option>
                  <option value="administrador">Admintrador</option>
                  <option value="funcionário">Funcionário</option>
                </select>
              </div>
              <div class="form-group col-md-3" v-if="user.foreign_key_farm === 1 && getListFarmsId(user.foreign_key_farm) === 'vaca roxa' && user.account_type == 'administrador'">
                <label for="fazenda">Fazenda</label>
                <select class="form-control" id="fazenda" v-model="formData.foreign_key_farm">
                  <option value="" disabled>SELECIONE UMA FAZENDA</option>
                  <option v-for="item in filteredList" :key="item.id" :value="item.id">{{ item.farm_name.toUpperCase() }}</option>
                </select>
              </div>
              <div class="form-group col-12 py-3">
                <button type="submit" class="btn btn-green w-100">Salvar</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </template>
  
<script>
  import axios from 'axios';

  export default {
    data() {
      return {
        user: {},
        listFarms: [],
        servidor: 'https://api001.fazd.com.br',
        list: [],
        formData: {
          name: '',
          email: '',
          password: '',
          account_type: '',
          foreign_key_farm: '',
          status: 1,
        },
        
      }
    },
    mounted() {
      this.getUserData()
      this.getListFarms()
      this.getList()
    },
    computed: {
      filteredList() {
        return this.list.filter(listItem =>
          Object.values(listItem).some(value =>
            String(value)
          )
        );
      },
    },
    methods: {
      async gerarRegistro() {
        try {
          const response = await axios.post(this.servidor + '/api/users', this.formData);
          //console.log(response.data);
          this.$router.push('/dashboard-usuarios');
        } catch (error) {
          console.error(error);
        }
      },
      getList() {
        axios.get(this.servidor + '/api/farms', {
            headers: {
              'Accept': 'application/json',
            },
        })
            .then(response => {
                this.list = response.data
            })
            .catch(error => {
                console.error(error)
            })
      },
      getUserData() {
        axios.get(this.servidor + '/api/user', {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
          }
        })
        .then(response => {
          this.user = response.data;
          this.formData.foreign_key_farm = this.user.foreign_key_farm;
          localStorage.setItem('farm', this.user.foreign_key_farm);
        })
        .catch(error => {
          console.error(error)
        });
      },
      getListFarms() {
          axios.get(this.servidor+'/api/farms', {
              headers: {
              'Accept': 'application/json',
              },
          })
              .then(response => {
                  this.listFarms = response.data
              })
              .catch(error => {
                  console.error(error)
              })
      },
      getListFarmsId(id) {
          const farm = this.listFarms.find(item => item.id === id);
          return farm ? farm.farm_name : '';
      },
    },
  };
</script>
  
  <style scoped>
  .form-control {
    border-color: #ced4da;
  }
  
  .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
  }
  </style>