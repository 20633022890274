<template>
  <div>
    <!-- Navbar -->
    <Navbar />

    <div class="container-fluid">
      <div class="row">
        <!-- Sidebar -->
        <Sidebar />
        
        <!-- Conteúdo principal -->
        <main role="main" class="main-content">
          <TabelaCategorias />
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue';
import Sidebar from '@/components/Sidebar.vue';
import TabelaCategorias from '@/components/TabelaCategorias.vue';

export default {
  name: 'Dashboard',
  components: {
    Navbar,
    Sidebar,
    TabelaCategorias,
  },
}
</script>

<style>

</style>