<template>
  <nav class="navbar fixed-top navbar-expand-lg navbar-success bg-success " style="z-index: 999;">
    <img src="/img/logo.png" id="logo" class="logo-img" alt="logo">
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse menu" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link corWhite" aria-current="page" href="#">Início</a>
        </li>
        <li class="nav-item">
          <a class="nav-link corWhite" href="#sobre-point">Sobre</a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link  corWhite" href="#especialidades-point">
            Especialidades
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link corWhite" href="#venda-point">Venda</a>
        </li>
        <li class="nav-item">
          <a class="nav-link corWhite" href="#venda-point">Parceiros</a>
        </li>
        <li class="nav-item">
          <a class="nav-link corWhite" href="#contato-point">Entre em contato</a>
        </li>
      </ul>
      <button class="btn-login" @click="openLoginPage()"  v-if="!this.user" >Login</button>
      <button class="btn-login" @click="logout()" v-if="this.user" >Sair</button>
    </div>
  </nav>
</template>

<script>
  
  export default {
    data() {
      return {
        user: localStorage.getItem('token'),
      }
    },
    methods: {
      openLoginPage() {
        const loginPageRoute = '/login';
        window.open(loginPageRoute, '_blank');
      },
      logout() {
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('token');
        localStorage.removeItem('farm');
        this.$router.push('/login');
      },
    }
  }
</script>
<style scoped>

.menu {
  position: absolute;
  right: 0px;
  margin-right: 50px;
}

.menu a {
  text-decoration: none;
  color: #fff;
  padding: 5px 10px;
  position: relative; /* Para que a barra seja posicionada em relação a este elemento */
}

.menu a:hover::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #fff;
}

.btn-login {
  color: #fff;
  border: 1px solid #fff;
  background-color: #60a83a;
  padding: 8px 16px;
  margin-right: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-login:hover {
  background-color: #509030;
}

.corWhite {
  color: white;
}

.corblack {
  color: black !important;
}

@media (max-width: 991px){

  .menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 15px;
  }
  .menu a {
    color: black;
    
  }
}

</style>