<template>
  <div class="container-fluid py-4">
    <div class="row row-style">
      <div class="table-responsive-lg col-12">
        <div class="card col-md-12" id="table-card">
          <div class="form-group">
            <input type="text" class="form-control search-table" v-model="searchTerm" placeholder="Pesquisar">
          </div>
        </div>
        <table class="table table-hover table-pastel mb-0 mt-0">
          <thead class="thead-dark">
            <tr>
              <th>Tipo de Animal</th>
            </tr>
          </thead>
          <tbody>
            <tr class="text-capitalize" v-for="item in filteredListCategory" :key="item.id">
              <td>{{ item.category }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

</template>

<script>
import axios from 'axios';
import "@/assets/css/table.css";

export default {
  data() {
    return {
      searchTerm: '',
      list: [],
      servidor: 'http://api001.fazd.com.br',
    }
  },
  mounted() {
      this.getListCategory()
  },
  computed: {
    filteredListCategory() {
      const term = this.searchTerm.toLowerCase()

      return this.list.filter(listItem =>
        Object.values(listItem).some(value =>
          String(value).toLowerCase().includes(term)
        )
      );
    },
  },
  methods: {
    getListCategory() {
        axios.get(this.servidor+'/api/animals-categories', {
            headers: {
              'Accept': 'application/json',
            },
        })
            .then(response => {
                this.list = response.data
            })
            .catch(error => {
                console.error(error)
            })
    },
  },
};
</script>

<style>
  .row-style {
    margin: auto!important;
  }

  .thead-dark > tr > th {
    background-color: #fff!important
  }

  #table-card {
    background-color: #198754db!important;
    box-shadow: #149d5d 0px 1px 7px 0px;
    border: 0;
    height: 58px;
    padding: 0;
  }

  .modal-title {
    font-weight: bold;
  }

  .btn-green {
    background-color: #198754db;
    border-color: #198754db;
    color: #fff!important;
  }

  tbody tr {
    line-height: 5;
  }

</style>