<template>
  <div class="container" >
    <Navbar />
    <Sidebar />
  </div>
  <div class="container">
    <Grafico/>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue';
import Sidebar from '@/components/Sidebar.vue';
import Grafico from '@/components/Grafico.vue';
import ArvoreGenealogica from '@/components/ArvoreGenealogica.vue';

export default {
  name: 'Home',
  components: {
    Navbar,
    Sidebar,
    Grafico,
    ArvoreGenealogica
  },
}
</script> 

<style>

</style>