<template>
    <div id="venda-point" class="row">
        <h2 class="main-title text-center mt-5 mb-3">CONHEÇA OS ANIMAIS A VENDA</h2>

        <div id="carouselExampleCaptions" class="carousel carousel-dark slide" data-bs-ride="false">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <div class="card-group">
                        <div class="card text-bg-light">
                            <img src="/img/vendaAnimal1.jpg" class="card-img-top" alt="">
                            <div class="card-body">
                                <h5 class="card-title">Demonstração</h5>
                                <p class="card-text">2 anos, 95 kilos e muito saudavel</p>
                            </div>
                        </div>
                        <div class="card text-bg-light">
                            <img src="/img/VendaAnimal2.jpg" class="card-img-top" alt="">
                            <div class="card-body">
                                <h5 class="card-title">Demonstração</h5>
                                <p class="card-text">2 anos, 95 kilos e muito saudavel</p>
                            </div>
                        </div>
                        <div class="card text-bg-light">
                            <img src="/img/VendaAnimal3.jpg" class="card-img-top" alt="">
                            <div class="card-body">
                                <h5 class="card-title">Demonstração</h5>
                                <p class="card-text">2 anos, 95 kilos e muito saudavel</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="card-group">
                        <div class="card text-bg-light">
                            <img src="/img/VendaAnimal4.jpg" class="card-img-top" alt="">
                            <div class="card-body">
                                <h5 class="card-title">Demonstração</h5>
                                <p class="card-text">2 anos, 95 kilos e muito saudavel</p>
                            </div>
                        </div>
                        <div class="card text-bg-light">
                            <img src="/img/VendaAnimal5.jpg" class="card-img-top" alt="">
                            <div class="card-body">
                                <h5 class="card-title">Demonstração</h5>
                                <p class="card-text">2 anos, 95 kilos e muito saudavel</p>
                            </div>
                        </div>
                        <div class="card text-bg-light">
                            <img src="/img/VendaAnimal6.jpg" class="card-img-top" alt="">
                            <div class="card-body">
                                <h5 class="card-title">Demonstração</h5>
                                <p class="card-text">2 anos, 95 kilos e muito saudavel</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>
    </div>
</template>
<script>

</script>
<style>


</style>