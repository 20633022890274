<template>
  <body>
    <div id="contem-login">
      <div id="texto">
        <b>SEJA BEM-VINDO AO FAZD</b>
      </div>
      <div class="container ">
        <div class="row justify-content-center">
          <div class="box-login">
            <div class="card-login">
              <div class="card-body">
                <form @submit.prevent="login">
                  <div class="form-group">
                    <label for="email"> <b>E-mail</b> </label>
                    <input type="email" id="email" class="form-control" v-model="email" required>
                  </div>
                  <div class="form-group">
                    <label for="password"><b>Senha</b></label>
                    <input type="password" id="password" class="form-control" v-model="password" required>
                  </div>
                  <button type="submit"  class="btn btn-primary butao-login"> <b>Login</b> </button>
                </form>
              </div>
            </div>
        </div>
      </div>
    </div>
    </div>
  </body>
</template>

<script>
import "@/assets/css/login.css";
import axios from 'axios';

export default {
  data() {
    return {
      email: '',
      password: '',
      isLoggedIn: false
    };
  },
  mounted() {
    if(localStorage.getItem('token'))
    {
      this.$router.push('/home');
    }
  },
  methods: {
    async login() {
        try {
          const response = await axios.post('https://api001.fazd.com.br/api/login', {
            email: this.email,
            password: this.password
          });

          this.isLoggedIn = true;

          localStorage.setItem('isLoggedIn', this.isLoggedIn);
          localStorage.setItem('token', response.data.token);

          this.$router.push('/home');
            
        } catch (error) {
          alert('Usuário ou senha inválidos!');
        }
      }
    }
};
</script>

<style scoped>
  body {
    min-width: 100%;
    height: 1000px;
    margin: 0;
    padding: 0;
    background-image: url('/public/img/login.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
  }
</style>