<template>
    <div class="row" id="sobre-point">
        <h2 class="main-title col-md-12 text-center mt-5 mb-3">TRANSFORMAMOS SUA FAZENDA</h2>

        <div class="video-container col-md-6 mt-4">
            <video controls autoplay>
                <source src="img/videoIntro.mp4" type="video/mp4">
                Seu navegador não suporta o elemento de vídeo.
            </video>
        </div>
        <div class="col-md-6 mt-4">
            <h4  class="about-title">Tornamos sua fazenda em digital</h4>
            <p> Transformar a propriedade rural em digital é ter informações em tempo, 
                acompanhamento do que acontece no campo com o objetivo de tomar decisão assertiva evitando perdas de recursos financeiros, 
                materiais e tempo.</p>
            <ul class="about-list">
                <li><i class="fas fa-check"></i> Organize seus bezerros</li>
                <li><i class="fas fa-check"></i> Negocie com outras fazendas</li>
                <li><i class="fas fa-check"></i> veja o melhor do mercado</li>
                <li><i class="fas fa-check"></i> anuncie sua empresa</li>
                <li><i class="fas fa-check"></i> toda sua fazenda digital</li>
            </ul>
        </div>
    </div>
</template>
<script>

</script>
<style>
.main-title {
    color: #444;
}

.about-title {
    color: #444;
    text-transform: uppercase;
}

.about-list {
    list-style: none;
    padding-left: 0;
}
.about-list li {
    line-height: 30px;
}
.about-list i {
    color: #198754;
    margin-right: 15px;
}

.video-container video {
    width: 100%; /* Preenche toda a largura do container */
}
</style>