<template>
  <div class="container-fluid py-4">
    <div class="row row-style">
      <div class="table-responsive-lg col-12">
        
        <div class="card col-md-12" id="table-card">
          <div class="form-group">
            <input type="text" class="form-control search-table" v-model="searchTerm" placeholder="Pesquisar">
          </div>
        </div>
        <table class="table table-hover table-pastel mb-0 mt-0">
          <thead class="thead-dark">
            <tr>
              <th>Nome</th>
              <th>E-mail</th>
              <th>Tipo de Conta</th>
              <th>Fazenda</th>
            </tr>
          </thead>
          <tbody>
            <tr class="text-capitalize" v-for="item in filteredListUsers" :key="item.id">
              <td>{{ item.name }}</td>
              <td class="text-lowercase">{{ item.email }}</td>
              <td>{{ item.account_type}}</td>
              <td>{{ item.farm }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

</template>

<script>
import axios from 'axios';
import "@/assets/css/table.css";

export default {
  data() {
    return {
      searchTerm: '',
      list: [],
      listUsers: [],
      listFarms: [],
      user: {},
      servidor: 'https://api001.fazd.com.br',
    }
  },
  mounted() {
      this.getUserData()
      this.getListUsers()
      this.getListFarms()
  },
  computed: {
    filteredListUsers() {
      const term = this.searchTerm.toLowerCase()
      
      this.transformArray()

      return this.list.filter(listItem =>
        Object.values(listItem).some(value =>
          String(value).toLowerCase().includes(term)
        )
      );
    },
  },
  methods: {
    getUserData() {
      axios.get(this.servidor + '/api/user', {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      })
      .then(response => {
        this.user = response.data;
      })
      .catch(error => {
        console.error(error)
      });
    },

    getListUsers() {
        axios.get(this.servidor+'/api/users', {
            headers: {
              'Accept': 'application/json',
            },
        })
            .then(response => {
                this.listUsers = response.data
            })
            .catch(error => {
                console.error(error)
            })
    },

    getListFarms() {
      axios.get(this.servidor+'/api/farms', {
            headers: {
              'Accept': 'application/json',
            },
        })
            .then(response => {
                this.listFarms = response.data
            })
            .catch(error => {
                console.error(error)
            })
    },

    transformArray() {
      this.list = this.listUsers
        .filter(user => user.foreign_key_farm === this.user.foreign_key_farm)
        .map(item => ({
          id: item.id,
          name: item.name,
          email: item.email,
          account_type: item.account_type,
          foreign_key_farm: item.foreign_key_farm,
          farm: this.getListFarmsId(item.foreign_key_farm),
          status: item.status
        }));
    },

    getListFarmsId(id) {
      const farm = this.listFarms.find(item => item.id === id);
      return farm ? farm.farm_name : '';
    },
    
  },
};
</script>

<style>
  .row-style {
    margin: auto!important;
  }

  .thead-dark > tr > th {
    background-color: #fff!important
  }

  #table-card {
    background-color: #198754db!important;
    box-shadow: #149d5d 0px 1px 7px 0px;
    border: 0;
    height: 58px;
    padding: 0;
  }

  .modal-title {
    font-weight: bold;
  }

  .btn-green {
    background-color: #198754db;
    border-color: #198754db;
    color: #fff!important;
  }

  tbody tr {
    line-height: 5;
  }

</style>