<template>
    <div id="showtext" class="shadow p-3 mb-5 rounded">
        <h2>CONHEÇA OS ANIMAIS A VENDA</h2>
        <h4>cadastre seus bezerros</h4>
        <h6>faça parte do FAZD</h6>
    </div>
</template>

<script>

</script>
<style>
#showtext {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: #fdfd96;
}

#showtext h2, h4, h6 {
    font-size: x-large;
    font-family: Arial, Helvetica, sans-serif;
}

</style>