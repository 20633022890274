<template>
    <div class="container-fluid py-4">
      <div class="row row-style">
        <div class="col-12">
          <form id="fazenda-form" @submit.prevent="gerarRegistro">
            <div class="row">
              <div class="form-group col-md-6">
                <label for="farmName">Nome da Fazenda</label>
                <input type="text" class="form-control" id="farmName" v-model="formData.farm_name" placeholder="Digite o nome da fazenda">
              </div>
              <div class="form-group col-md-6">
                <label for="responsavel">Nome do Responsável</label>
                <input type="text" class="form-control" id="responsavel" v-model="formData.owner" placeholder="Digite o nome do responsável da fazenda">
              </div>
              <div class="form-group col-md-6">
                <label for="endereco">Endereço</label>
                <input type="text" class="form-control" id="endereco" v-model="formData.address" placeholder="Endereço da fazenda">
              </div>
              <div class="form-group col-md-3">
                <label for="telefone">Telefone</label>
                <input type="text" class="form-control" id="telefone" v-model="formData.tel1" placeholder="Telefone">
              </div>
              <div class="form-group col-md-3">
                <label for="whatsapp">Whatsapp</label>
                <input type="text" class="form-control" id="whatsapp" v-model="formData.tel2" placeholder="Whatsapp">
              </div>
              <div class="form-group col-12 py-3">
                <button type="submit" class="btn btn-green w-100">Salvar</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </template>
  
<script>
  import axios from 'axios';

  export default {
    data() {
      return {
        formData: {
          farm_name: '',
          owner: '',
          address: '',
          tel1: '',
          tel2: '',
          status: 1,
        },
      };
    },
    methods: {
      async gerarRegistro() {
        try {
          const response = await axios.post('https://api001.fazd.com.br/api/farms', this.formData);
          console.log(response.data);
          this.$router.push('/dashboard-fazendas');
        } catch (error) {
          console.error(error);
        }
      },
    },
  };
</script>
  
  <style scoped>
  .form-control {
    border-color: #ced4da;
  }
  
  .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
  }
  </style>