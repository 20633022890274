<template>
  <div class="row">
    <h2 class="main-title text-center mt-5 mb-3">NOSSOS PARCEIROS</h2>
    <div class="row align-items-center partner-row">
      <div class="col">
        <a href="https://www.agrifirm.com.br/" target="_blank"><img class="partner-logo" src="/img/logo-agrifirm-rgb-274x90-2.png" alt="LogoParceiro"></a>
      </div>
      <div class="col">
        <a href="https://vacaroxaa.com/" target="_blank"><img class="partner-logo" src="/img/LogoVacaRoxa.jpg" alt="LogoParceiro"></a>
      </div>
      <div class="col">
        <img class="partner-logo" src="/img/marconLogo.jpg" alt="LogoParceiro">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // ...
};
</script>

<style scoped>
.partner-logo {
  max-width: 80%;
  height: auto;
  margin: 10px;
}
</style>