<template>
  <div>
    <h2 class="title-page">Árvore genealógica</h2>
    <div class="organograma">
      <div class="node" :class="{ expanded: node.expanded, 'root-node': node.id === startingAnimalId }" v-for="node in nodes" :key="node.id">
        <span class="node-title" @click="toggleNode(node)">{{ node.surname }}</span>
        <div class="node-children" v-show="node.expanded">
          <div class="node" v-for="child in node.children" :key="child.id">
            <span class="node-title" @click="toggleNode(child)">{{ child.surname }}</span>
            <div class="node-children" v-show="child.expanded">
              <div class="node" v-for="grandchild in child.children" :key="grandchild.id">
                <span class="node-title">{{ grandchild.surname }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      nodes: [],
      startingAnimalId: 7,
    };
  },
  mounted() {
    this.fetchTreeData();
  },
  methods: {
    async fetchTreeData() {
      try {
        const response = await fetch('https://api001.fazd.com.br/api/animals');
        const data = await response.json();
        const startingAnimal = data.find(animal => animal.id === this.startingAnimalId);
        if (startingAnimal) {
          this.buildTree(startingAnimal, data);
        } else {
          console.error('Animal inicial não encontrado.');
        }
      } catch (error) {
        console.error('Erro ao carregar os dados:', error);
      }
    },
    buildTree(animal, allData) {
      const children = allData.filter(child => child.foreign_key_father === animal.id || child.foreign_key_mother === animal.id);
      animal.children = children;
      this.nodes.push(animal);

      if (animal.foreign_key_father !== null) {
        const father = allData.find(parent => parent.id === animal.foreign_key_father);
        if (father) {
          this.buildTree(father, allData);
        }
      }

      if (animal.foreign_key_mother !== null) {
        const mother = allData.find(parent => parent.id === animal.foreign_key_mother);
        if (mother) {
          this.buildTree(mother, allData);
        }
      }
    },
    toggleNode(node) {
      node.expanded = !node.expanded;
    },
  },
};
</script>


<style>
.title-page {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.organograma {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.node {
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
}

.node-title {
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: dotted;
  border-width: 0px 0px 2px;
  background-color: #00ff89;
  border-radius: 10px;
  padding: 2px 1rem;
}

.node-children {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: 20px;
}

.root-node {
  justify-content: center;
}

.expanded .node-children {
  display: flex;
}
</style>