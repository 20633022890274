<template>
    <div class="container-fluid py-4">
      <div class="row row-style">
        <div class="col-12">
          <form id="bezerro-form" @submit.prevent="gerarRegistro" enctype="multipart/form-data">
            <div class="row">
              <div class="form-group col-md-6">
                <label for="nome">Nome do animal <span>(Opcional)</span></label>
                <input type="text" class="form-control" id="nome" v-model="nomeBezerro" placeholder="Digite o nome do animal">
              </div>
              <div class="form-group col-md-6">
                <label for="brinco">Número do brinco</label>
                <input type="number" class="form-control" id="brinco" v-model="numeroBrinco" placeholder="Número do brinco">
              </div>
              <div class="form-group col-md-2">
                <label for="categoria">Tipo de Animal</label>
                <select class="form-control" id="categoria" v-model="categoria">
                  <option value="" disabled>SELECIONE UM TIPO DE ANIMAL</option>
                  <option v-for="item in filteredListCategoria" :key="item.id" :value="item.id">{{ item.category.toUpperCase() }}</option>
                </select>
              </div>
              <div class="form-group col-md-2">
                <label for="raca">Raça</label>
                <select class="form-control" id="raca" v-model="raca" :disabled="this.categoria == ''">
                  <option value="" disabled>SELECIONE UMA RAÇA</option>
                  <option v-for="item in filteredListRaca" :key="item.id" :value="item.id">{{ item.breed.toUpperCase() }}</option>
                </select>
              </div>
              <div class="form-group col-md-2">
                <label for="numLactacao">Número de Lactação</label>
                <input type="number" class="form-control" id="numLactacao" v-model="lactacao" placeholder="Número de Lactação">
              </div>
              <div class="form-group col-md-2">
                <label for="valor">Valor</label>
                <input type="text" class="form-control" id="valor" v-model="preco" placeholder="Valor de mercado">
              </div>
              <div class="form-group col-md-2">
                <label for="pesoNasc">Peso ao nascer</label>
                <input type="text" class="form-control" id="pesoNasc" v-model="pesoNasc" placeholder="Peso ao nascer">
              </div>
              <div class="form-group col-md-2">
                <label for="pesoAtual">Peso atual</label>
                <input type="text" class="form-control" id="pesoAtual" v-model="pesoAtual" placeholder="Peso atual">
              </div>
              <div class="form-group col-md-2">
                <label for="alturaNasc">Altura ao nascer</label>
                <input type="text" class="form-control" id="alturaNasc" v-model="alturaNasc" placeholder="Altura ao nascer" @input="validarNumeroDecimal">
              </div>
              <div class="form-group col-md-2">
                <label for="alturaAtual">Altura atual</label>
                <input type="text" class="form-control" id="alturaAtual" v-model="alturaAtual" placeholder="Altura atual" @input="validarNumeroDecimal">
              </div>
              <div class="form-group col-md-2">
                <label for="dataNascimento">Data de nascimento</label>
                <input type="date" class="form-control" id="dataNascimento" v-model="dataNasc" placeholder="Data de nascimento">
              </div>
              <div class="form-group col-md-2">
                <label for="pai">Pai</label>
                <select class="form-control" id="pai" v-model="pai">
                  <option value="" disabled>SELECIONE O PAI</option>
                  <option v-for="item in filteredListAnimalsM" :key="item.id" :value="item.id">{{ item.surname.toUpperCase() }}</option>
                </select>
              </div>
              <div class="form-group col-md-2">
                <label for="mae">Mãe</label>
                <select class="form-control" id="mae" v-model="mae">
                  <option value="" disabled>SELECIONE A MÃE</option>
                  <option v-for="item in filteredListAnimalsF" :key="item.id" :value="item.id">{{ item.surname.toUpperCase() }}</option>
                </select>
              </div>
              <div class="form-group col-md-2">
                <label for="genero">Gênero</label>
                <select class="form-control" id="genero" v-model="gender">
                  <option value="" disabled>SELECIONE UM GÊNERO</option>
                  <option value="m">Macho</option>
                  <option value="f">Fêmea</option>  
                </select>
              </div>
              <div  class="form-group">
                <label for="image">Imagem do animal</label>
                <input type="file" class="form-control-file" id="image" ref="image" @change="onImagemSelecionada">
              </div>
              <div class="form-group col-12 py-3">
                <button type="submit" class="btn btn-green w-100">Salvar</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </template>
  
<script>
  import axios from 'axios';

  export default {
    data() {
      return {
        listCategoria: [],
        listRaca: [],
        listAnimal: [],
        nomeBezerro: '',
        numeroBrinco: '',
        categoria: '',
        raca: '',
        lactacao: '',
        preco: '',
        pesoNasc: '',
        pesoAtual: '',
        alturaNasc: '',
        alturaAtual: '',
        dataNasc: '',
        gender: '',
        image: '',
        pai: '',
        mae: '',
        farm: localStorage.getItem('farm'),
      };
    },
    mounted() {
      this.getListCategoria()
      this.getListRaca()
      this.getListAnimals()
    },
    computed: {
      filteredListCategoria() {
        return this.listCategoria.filter(listItemCategoria =>
          Object.values(listItemCategoria).some(value =>
            String(value)
          )
        );
      },
      filteredListRaca() {
        return this.listRaca.filter(listItemRaca => listItemRaca.foreign_key_category === this.categoria)
      },
      filteredListAnimalsM() {
        if (this.categoria) {
          return this.listAnimal.filter(animal => animal.foreign_key_breed === this.raca && animal.gender === "m");
        } 
      },
      filteredListAnimalsF() {

        if (this.categoria) {
          return this.listAnimal.filter(animal => animal.foreign_key_breed === this.raca && animal.gender === "f");
        } 
      },
    },
    methods: {
      onImagemSelecionada(event) {
        this.image = event.target.files[0]
      },

      gerarRegistro() {
        const formData = new FormData()

        formData.append('surname', this.nomeBezerro)
        formData.append('earring', this.numeroBrinco)
        formData.append('foreign_key_breed', this.raca)
        formData.append('lactation', this.lactacao)
        formData.append('price', this.preco)
        formData.append('weigth_first', this.pesoNasc)
        formData.append('weigth_last', this.pesoAtual)
        formData.append('height_fist', this.alturaNasc)
        formData.append('height_last', this.alturaAtual)
        formData.append('birth', this.dataNasc)
        formData.append('gender', this.gender)
        formData.append('image', this.image)
        formData.append('foreign_key_father', this.pai)
        formData.append('foreign_key_mother', this.mae)
        formData.append('foreign_key_farm', this.farm)

        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        };

        axios.post('https://api001.fazd.com.br/api/animals', formData, config)
          .then(response => {
            console.log("Animal cadastrado")
            this.$router.push('/dashboard-animais')
          })
            .catch(error => {
              if (error.response) {
                console.error(error.response.data.error)
              } else {
                console.error(error.message)
              }
            });
      },
      getListCategoria() {
        axios.get('https://api001.fazd.com.br/api/animals-categories', {
            headers: {
              'Accept': 'application/json',
            },
        })
            .then(response => {
                this.listCategoria = response.data
            })
            .catch(error => {
                console.error(error)
            })
      },
      getListRaca() {
        axios.get('https://api001.fazd.com.br/api/animals-breeds', {
            headers: {
              'Accept': 'application/json',
            },
        })
            .then(response => {
                this.listRaca = response.data
            })
            .catch(error => {
                console.error(error)
            })
      },
      getListAnimals() {
        axios.get('https://api001.fazd.com.br/api/animals', {
            headers: {
              'Accept': 'application/json',
            },
        })
            .then(response => {
                this.listAnimal = response.data
            })
            .catch(error => {
                console.error(error)
            })
      },
    },
  };
</script>
  
  <style scoped>
  .form-control {
    border-color: #ced4da;
  }
  
  .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
  }
  </style>