<template>
   <h2 class="main-title text-center mt-5 mb-3">GRAFICOS</h2>
   <caption class="col-md-12">aqui sera implementado graficos para o monitoramento dos animais da fazenda e geracao de dados </caption>
</template>

<script>

</script>

<style>

</style>