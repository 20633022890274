<template>
  <div id="contato-point" class="row">
    <h2 class="main-title text-center mt-5 mb-3">ENTRE EM CONTATO CONOSCO</h2>
    <div class="col-md-4 contact-box">
      <div class="icons-box">
        <i class="fas fa-phone"></i>
      </div>
      <p><span class="contact-title">Ligue para:</span> +55 49 9180-3197</p>
      <p><span class="contact-title">Horarios:</span> 08:00 as 18:00</p>
    </div>
    <div class="col-md-4 contact-box">
      <div class="icons-box">
        <i class="fas fa-envelope"></i>
      </div>
      <p><span class="contact-title">Envie um e-mail:</span> contato@fazd.com.br</p>
    </div>
    <div class="col-md-4 contact-box">
      <div class="icons-box">
        <i class="fas fa-map-marker-alt"></i>
      </div>
      <p>
        <span class="contact-title">Conheça nossa fazenda: <br></span>
        <a href="https://goo.gl/maps/ALCttfy68LvAcBJS9" target="_blank" rel="noopener noreferrer">https://goo.gl/maps/ALCttfy68LvAcBJS9</a>
      </p>
    </div>
    <div class="col-md-6" id="msg-box">
      <p class="main-title mens">Ou nos deixe uma mensagem:</p>
    </div>
    <div class="col-md-6" id="contact-form">
      <form @submit.prevent="enviarMensagem">
        <input type="email" class="form-control" placeholder="E-mail" v-model="mensagem.email">
        <input type="text" class="form-control" placeholder="Assunto" v-model="mensagem.subject">
        <textarea class="form-control" rows="3" placeholder="Sua mensagem..." v-model="mensagem.message"></textarea>
        <button type="submit" class="main-btn">Enviar</button>
      </form>
    </div>
  </div>

  <CopyW />
</template>

<script>
  import CopyW from '@/components/CopyW.vue';
  import axios from 'axios'; 

  export default {
    components: {
      CopyW,
    },
    data() {
      return {
        mensagem: {
          email: '',
          subject: '',
          message: '',
        },
      };
    },
    methods: {
    enviarMensagem() {
        axios.post('https://api001.fazd.com.br/api/enviar-mensagem', this.mensagem)
        .then(response => {
          console.log(response);
        })
        .catch(error => {
          console.error(error); 
        });
      },
    },
  };
</script>

<style scoped>

  .contact-box {
    padding-bottom: 50px;
    margin-bottom: 50px;
    border-bottom: 1px solid #3EC1D5;
    text-align: center;
  }
  
  .contact-box p {
    font-size: 16px;
  }

  .icons-box {
    color: #444;
    border: solid 1px #444;
    border-radius: 50%;
    width: 55px;
    height: 55px;
    line-height: 55px;
    font-size: 24px; 
    margin-bottom: 20px;
    text-align: center;
    display: inline-block;

  }

  .contact-title {
    font-weight: bold;
    color:  #3EC1D5;
  }

  .msg-box p {
    font-size: 22px;
  }
  #contact-form input[type="text"], #contact-form input[type="email"], #contact-form textarea {
    margin-bottom: 15px;
    border-radius: 0; 
  }
  .mens {
    font-size: 30px;
  }

  #contact-form .main-btn {
    width: 120px;
    height: 50px;
  }

  #contact-form .main-btn:hover {
    background-color: #3EC1D5;
    color: white;
    transition: 1s;
  }


</style>
  