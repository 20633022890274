<template>
    <nav class="col-md-2 d-md-block sidebar">
        <div class="sidebar-sticky">
            <ul class="nav flex-column">
                <li class="nav-item">
                    <button class="btn btn-block btn-sidebar" @click="navigateTo('/home')">
                        <i class="fas fa-home"></i> Home
                    </button>
                </li>
                <li class="nav-item">
                    <div class="dropdown">
                        <button class="btn btn-block btn-sidebar dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="fas fa-table-list"></i> Dashboards
                        </button>
                        <ul class="dropdown-menu dropdown-menu-ligth menu-dropdown">
                            <li>
                                <button class="btn btn-block btn-sidebar" @click="navigateTo('/dashboard-animais')">
                                    <i class="fas fa-cow"></i> Animais
                                </button>
                            </li>
                            <li v-if="user.foreign_key_farm === 1 && getListFarmsId(user.foreign_key_farm) === 'vaca roxa' && user.account_type == 'administrador' ">
                                <button class="btn btn-block btn-sidebar" @click="navigateTo('/dashboard-fazendas')">
                                    <i class="fas fa-wheat-awn"></i> Fazendas
                                </button>
                            </li>
                            <li v-if="user.foreign_key_farm === 1 && getListFarmsId(user.foreign_key_farm) === 'vaca roxa' && user.account_type == 'administrador' ">
                                <button class="btn btn-block btn-sidebar" @click="navigateTo('/dashboard-categorias')">
                                    <i class="fas fa-list-ul"></i> Categorias
                                </button>
                            </li>
                            <li v-if="user.foreign_key_farm === 1 && getListFarmsId(user.foreign_key_farm) === 'vaca roxa' && user.account_type == 'administrador' ">
                                <button class="btn btn-block btn-sidebar" @click="navigateTo('/dashboard-racas')">
                                    <i class="fas fa-magnet"></i> Raças
                                </button>
                            </li>
                            <li v-if="user.account_type == 'administrador'">
                                <button class="btn btn-block btn-sidebar" @click="navigateTo('/dashboard-usuarios')">
                                    <i class="fas fa-users"></i> Usuários
                                </button>
                            </li>
                        </ul>
                    </div>
                </li>
                <li class="nav-item">
                    <button class="btn btn-block btn-sidebar" @click="navigateTo('/novo-animal')">
                        <i class="fas fa-cow"></i> Cadastrar Animal
                    </button>
                </li>
                <li class="nav-item" v-if="user.account_type == 'administrador'">
                    <div class="dropdown">
                        <button class="btn btn-block btn-sidebar dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="fas fa-gear"></i> Configurações
                        </button>
                        <ul class="dropdown-menu dropdown-menu-ligth menu-dropdown">
                            <li v-if="user.foreign_key_farm === 1 && getListFarmsId(user.foreign_key_farm) === 'vaca roxa' && user.account_type == 'administrador' ">
                                <button class="btn btn-block btn-sidebar" @click="navigateTo('/nova-fazenda')">
                                    <i class="fas fa-wheat-awn"></i> Cadastrar Fazenda
                                </button>
                            </li>
                            <li v-if="user.foreign_key_farm === 1 && getListFarmsId(user.foreign_key_farm) === 'vaca roxa' && user.account_type == 'administrador' ">
                                <button class="btn btn-block btn-sidebar" @click="navigateTo('/nova-categoria')">
                                    <i class="fas fa-list-ul"></i> Cadastrar Categoria
                                </button>
                            </li>
                            <li v-if="user.foreign_key_farm === 1 && getListFarmsId(user.foreign_key_farm) === 'vaca roxa' && user.account_type == 'administrador' ">
                                <button class="btn btn-block btn-sidebar" @click="navigateTo('/nova-raca')">
                                    <i class="fas fa-magnet"></i> Cadastrar Raça
                                </button>
                            </li>
                            <li>
                                <button class="btn btn-block btn-sidebar" @click="navigateTo('/novo-usuario')">
                                    <i class="fas fa-users"></i> Cadastrar Usuário
                                </button>
                            </li>
                        </ul>
                    </div>
                </li>
                <li>
                    <button class="btn btn-success d-lg-none w-100 rounded-0" @click="logout" data-toggle="modal" data-target="#logoutModal">
                        Sair
                    </button>
                </li>
            </ul>
        </div>
    </nav>
</template>
  
<script>
    import axios from 'axios';

    export default {
        name: "Sidebar",
        data() {
            return {
                user: {},
                listFarms: [],
                servidor: 'https://api001.fazd.com.br',
            }
        },
        mounted() {
            const navbarToggler = document.querySelector('.navbar-toggler');
            const sidebar = document.querySelector('.sidebar');

            navbarToggler.addEventListener('click', () => {
                sidebar.classList.toggle('show');
            });

            this.getUserData();
            this.getListFarms()
        },
        methods: {
            navigateTo(page) {
                this.$router.push(page)
            },
            logout() {
                localStorage.removeItem('isLoggedIn');
                // Redirecionar para a página de login
                this.$router.push('/');
            },
            getUserData() {
                axios.get('https://api001.fazd.com.br/api/user', {
                    headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                    }
                })
                    .then(response => {
                        this.user = response.data;
                    })
                    .catch(error => {
                        console.error(error)
                    });
            },
            getListFarms() {
                axios.get(this.servidor+'/api/farms', {
                    headers: {
                    'Accept': 'application/json',
                    },
                })
                    .then(response => {
                        this.listFarms = response.data
                    })
                    .catch(error => {
                        console.error(error)
                    })
            },
            getListFarmsId(id) {
                const farm = this.listFarms.find(item => item.id === id);
                return farm ? farm.farm_name : '';
            },
        }
    }
</script>

<style scoped>
    .menu-dropdown {
        position: relative !important;
        inset: auto !important;
        margin: 0px !important;
        transform: none !important;
        border-radius: 0 !important;
        background-color: #19875442!important;
    }

    .btn-sidebar:hover {
        background-color: #19875442!important;
        color: #000!important;
        border-color: transparent!important;
    }

    .btn-sidebar.show {
        border-color: transparent!important;
        background-color: #198754bd!important;
        color: #ffffff;
    }

    .btn-sidebar.show:hover {
        color: #ffffff!important;
    }

    .btn-sidebar.show i {
        color: #ffffff;
    }

    .btn-sidebar i {
        width: 20px;
    }
  
</style>