<template>
  <div class="row mt-5" id="copy-area">
      <div class="col-md-12 text-center">
        <p>Desenvolvido por <a href="https://www.bia.ind.br/pages/sign-up.html" target="_blank">Bia Technology</a> &COPY; 2023</p>
      </div>
  </div>
</template>

<script>

</script>

<style>
    #copy-area {
      background-color: #f1f1f1 !important;
      padding: 10px 0px;
      line-height: 50px;
      width: 100%;
      height: 70px;
      position: absolute;
      left: 0;
      right: 0;
    }

    #copy-area .container p {
      margin-bottom: 0px;
    }
    #copy-area .col-md-12 a {
      font-weight: bold;
      color: #444;
    }
    #copy-area .col-md-12 a:hover {
      text-decoration: none;
      color: #3EC1D5;
    }
</style>