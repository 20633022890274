<template>
  <div class="container-fluid py-4">
    <div class="row row-style">
      <div class="col-12">
        <form id="categoria-form" @submit.prevent="gerarRegistro">
          <div class="row">
            <div class="form-group col-12">
              <label for="categoria">Tipo de Animal</label>
              <input type="text" class="form-control" id="categoria" v-model="formData.category" placeholder="Digite o tipo de animal (ex: Bezerro)">
            </div>
            <div class="form-group col-12 py-3">
              <button type="submit" class="btn btn-green w-100">Salvar</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      formData: {
        category: '',
        status: 1,
        foreign_key_breed: 1,
      },
    };
  },
  methods: {
    async checkExistingCategory(category) {
      try {
        const response = await axios.get('https://api001.fazd.com.br/api/animals-categories');
        const categories = response.data;
        for (let i = 0; i < categories.length; i++) {
          if (categories[i].category.toLowerCase() === category.toLowerCase()) {
            return true;
          }
        }
        return false;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    async gerarRegistro() {
      try {
        const categoryExists = await this.checkExistingCategory(this.formData.category);
        if (categoryExists) {
          alert('O tipo de animal já existe. Por favor, insira um tipo de animal diferente.');
        } else {
          const response = await axios.post('https://api001.fazd.com.br/api/animals-categories', this.formData);
          console.log(response.data);
          this.$router.push('/dashboard-categorias');
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
.form-control {
  border-color: #ced4da;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}
</style>