<template>
  <div class="container-fluid py-4">
    <div class="row row-style">
      <div class="table-responsive-lg col-12">
        
        <div class="card col-md-12" id="table-card">
          <div class="form-group">
            <input type="text" class="form-control search-table" v-model="searchTerm" placeholder="Pesquisar">
          </div>
        </div>
        <table class="table table-hover table-pastel mb-0 mt-0">
          <thead class="thead-dark">
            <tr>
              <th style="width: 70px;"></th>
              <th>Nome</th>
              <th>Brinco</th>
              <th>Categoria</th>
              <th>Raça</th>
              <!--
              <th>Número de Lactação</th>
              <th>Valor</th>
              <th>Peso ao Nascer</th>
              <th>Peso Atual</th>
              <th>Altura ao Nascer</th>
              <th>Altura Atual</th>
              <th>Data de Nascimento</th>
              <th>Gênero</th>
              -->
              
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr class="text-capitalize" v-for="item in filteredListAnimals" :key="item.id">
              <td><img class="rounded-circle" :src="item.image" width="70" height="70"/></td>
              <td>{{ item.surname }}</td>
              <td>{{ item.earring }}</td>
              <td>{{ item.category}}</td>
              <td>{{ item.breed }}</td>
              <!--
              <td>{{ item.lactation }}</td>
              <td>{{ item.price.replace('.', ',') }}</td>
              <td>{{ item.weigth_first.replace('.', ',') }}</td>
              <td>{{ item.weigth_last.replace('.', ',') }}</td>
              <td>{{ item.height_fist.replace('.', ',') }}</td>
              <td>{{ item.height_last.replace('.', ',') }}</td>
              <td>{{ formatDate(item.birth) }}</td>
              <td>{{ item.gender=='m' ? 'masculino' : 'feminino' }}</td>
               -->
              
              <td>
                <button type="button" class="btn btn-green rounded-0" data-bs-toggle="modal" data-bs-target="#exampleModal" @click.stop="openItemModal(item)">
                  Detalhes
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content rounded-0">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">DETALHES</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal"></button>
        </div>
        <div class="modal-body">
          <div v-if="selectedItem">
            <form id="bezerro-form" @submit.prevent="" enctype="multipart/form-data">
              <div class="row">
                <div class="form-group col-md-8">
                  <label for="nome">Nome do animal</label>
                  <input type="text" class="form-control" id="nome" :value="selectedItem.surname" placeholder="Digite o nome do animal">
                </div>
                <div class="form-group col-md-4">
                  <label for="valor">Valor</label>
                  <input type="text" class="form-control" id="valor" :value="selectedItem.price" placeholder="Valor de mercado">
                </div>
                <div class="form-group col-md-6">
                  <label for="brinco">Número do brinco</label>
                  <input type="number" class="form-control" id="brinco" :value="selectedItem.earring" placeholder="Número do brinco">
                </div>
                <div class="form-group col-md-6">
                  <label for="numLactacao">Número de Lactação</label>
                  <input type="number" class="form-control" id="numLactacao" :value="selectedItem.lactation" placeholder="Número de Lactação">
                </div>
                <div class="form-group col-md-6">
                  <label for="categoria">Tipo de Animal</label>
                  <select class="form-control" id="categoria" :value="selectedItem.id_category">
                    <option v-for="item in listCategory" :key="item.id" :value="item.id">{{ item.category.toUpperCase() }}</option>
                  </select>
                </div>
                <div class="form-group col-md-6">
                  <label for="raca">Raça</label>
                  <select class="form-control" id="raca" :value="selectedItem.id_breed">
                    <option v-for="item in listBreed" :key="item.id" :value="item.id">{{ item.breed.toUpperCase() }}</option>
                  </select>
                </div>
                <div class="form-group col-md-6">
                  <label for="pesoNasc">Peso ao nascer</label>
                  <input type="text" class="form-control" id="pesoNasc" :value="selectedItem.weigth_first" placeholder="Peso ao nascer">
                </div>
                <div class="form-group col-md-6">
                  <label for="pesoAtual">Peso atual</label>
                  <input type="text" class="form-control" id="pesoAtual" :value="selectedItem.weigth_last" placeholder="Peso atual">
                </div>
                <div class="form-group col-md-6">
                  <label for="alturaNasc">Altura ao nascer</label>
                  <input type="text" class="form-control" id="alturaNasc" :value="selectedItem.height_fist" placeholder="Altura ao nascer" @input="validarNumeroDecimal">
                </div>
                <div class="form-group col-md-6">
                  <label for="alturaAtual">Altura atual</label>
                  <input type="text" class="form-control" id="alturaAtual" :value="selectedItem.height_last" placeholder="Altura atual" @input="validarNumeroDecimal">
                </div>
                <div class="form-group col-md-6">
                  <label for="dataNascimento">Data de nascimento</label>
                  <input type="date" class="form-control" id="dataNascimento" :value="selectedItem.birth" placeholder="Data de nascimento">
                </div>
                <div class="form-group col-md-6">
                  <label for="genero">Gênero</label>
                  <select class="form-control" id="genero" :value="selectedItem.gender =='masculino' ? 'm' : 'f'">
                    <option value="" disabled>SELECIONE UM GÊNERO</option>
                    <option value="m">Macho</option>
                    <option value="f">Fêmea</option>  
                  </select>
                </div>
                <img :src="selectedItem.image" width="200" alt="Imagem do Animal" />
              </div>
            </form>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary rounded-0" data-bs-dismiss="modal" @click="closeModal">Fechar</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import axios from 'axios';
import "@/assets/css/table.css";
import { format } from 'date-fns';

export default {
  data() {
    return {
      searchTerm: '',
      list: [],
      listAnimals: [],
      listBreed: [],
      listCategory: [],
      category: '',
      id_category: '',
      user: {},
      servidor: 'https://api001.fazd.com.br',
      selectedItem: null,
    }
  },
  mounted() {
      this.getUserData()
      this.getListAnimals()
      this.getListBreed()
      this.getListCategory()
  },
  computed: {
    filteredListAnimals() {
      const term = this.searchTerm.toLowerCase()
      
      this.transformArray()

      return this.list.filter(listItem =>
        Object.values(listItem).some(value =>
          String(value).toLowerCase().includes(term)
        )
      );
    },
  },
  methods: {
    getUserData() {
      axios.get(this.servidor + '/api/user', {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      })
      .then(response => {
        this.user = response.data;
        //console.log(this.user.foreign_key_farm)
      })
      .catch(error => {
        console.error(error)
      });
    },
    
    getListAnimals() {
        axios.get(this.servidor+'/api/animals', {
            headers: {
              'Accept': 'application/json',
            },
        })
            .then(response => {
                this.listAnimals = response.data
            })
            .catch(error => {
                console.error(error)
            })
    },

    getListBreed() {
      axios.get(this.servidor+'/api/animals-breeds', {
            headers: {
              'Accept': 'application/json',
            },
        })
            .then(response => {
                this.listBreed = response.data
            })
            .catch(error => {
                console.error(error)
            })
    },

    getListCategory() {
      axios.get(this.servidor+'/api/animals-categories', {
            headers: {
              'Accept': 'application/json',
            },
        })
            .then(response => {
                this.listCategory = response.data
            })
            .catch(error => {
                console.error(error)
            })
    },

    getAnimalTypeName(id) {
      const breed = this.listBreed.find(listItem => listItem.id === id);
      this.category = this.getAnimalCategory(breed ? breed.foreign_key_category : '') 

      return breed ? breed.breed : '';
    },

    getAnimalCategory(id) {
      const category = this.listCategory.find(listItem => listItem.id === id);
      this.id_category = id;

      return category ? category.category : '';
    },

    openItemModal(item) {
      this.selectedItem = item; 
    },

    closeModal() {
      this.selectedItem = null; 
    },

    transformArray() {
      this.list = this.listAnimals
        .filter(animal => animal.foreign_key_farm === this.user.foreign_key_farm) 
        .map(item => ({
          id: item.id,
          surname: item.surname,
          earring: item.earring,
          id_breed: item.foreign_key_breed,
          breed: this.getAnimalTypeName(item.foreign_key_breed),
          id_category: this.id_category,
          category: this.category,
          lactation: item.lactation,
          price: item.price.replace('.', ','),
          weigth_first: item.weigth_first.replace('.', ','),
          weigth_last: item.weigth_last.replace('.', ','),
          height_fist: item.height_fist.replace('.', ','),
          height_last: item.height_last.replace('.', ','),
          birth: item.birth,
          gender: item.gender === 'm' ? 'masculino' : 'feminino',
          image: this.servidor + '/' + item.image
        }));
    },
    formatDate(date) {
      return format(new Date(date), 'dd/MM/yyyy');
    },
  },
};
</script>

<style>
  .row-style {
    margin: auto!important;
  }

  .thead-dark > tr > th {
    background-color: #fff!important
  }

  #table-card {
    background-color: #198754db!important;
    box-shadow: #149d5d 0px 1px 7px 0px;
    border: 0;
    height: 58px;
    padding: 0;
  }

  .modal-title {
    font-weight: bold;
  }

  .btn-green {
    background-color: #198754db;
    border-color: #198754db;
    color: #fff!important;
  }

  tbody tr {
    line-height: 5;
  }

</style>