<template>
    <div id="especialidades-point" class="row">
        <h2 class="main-title text-center mt-5 mb-3">ESPECIALIDADES</h2>

        <div class="col-md-4 service-box">
            <h4><i class="fas fa-mobile-alt"></i> Aplicativo</h4>
            <p>Registro de bezerras, acompanhamento de crescimento e conversão alimentar, valorização genética.</p>
        </div>

        <div class="col-md-4 service-box">
            <h4> <i class="fas fa-shopping-cart"></i> E-commerce</h4>
            <p> Comércio permanente de bezerras e gado de corte.</p>
        </div>

        <div class="col-md-4 service-box">
            <h4><i class="fas fa-paint-brush"></i> atualidades</h4>
            <p>Entrevistas e indicações de equipamentos tecnológicos para a propriedade.</p>
        </div>

        <div class="col-md-4 service-box">
            <h4><i class="fab fa-google"></i> Coloque sua empresa no google</h4>
            <p>Estamos ativos no google para entregar o melhor produto e serviço</p>
        </div>

        <div class="col-md-4 service-box">
            <h4><i class="fas fa-hands-helping"></i> Parceria</h4>
            <p>Torne-se nosso parceiro</p>
        </div>

        <div class="col-md-4 service-box">
            <h4><i class="fas fa-server"></i> Vaca Roxa </h4>
            <p>Plataforma de monitoramento controle zootécnico de mastite bovina.</p>
        </div>

    </div>
</template>

<script>

</script>

<style>
 i {
    color: #198754;
 }

 h4 i {
    font-size: 20px;
 }
</style>
