import { createRouter, createWebHistory } from 'vue-router'
import CadastroView from '../views/CadastroView.vue'
import CadastroFazendaView from '../views/CadastroFazendaView.vue'
import CadastroCategoriaView from '../views/CadastroCategoriaView.vue'
import CadastroRacaView from '../views/CadastroRacaView.vue'
import CadastroUsuarioView from '../views/CadastroUsuarioView.vue'
import BezerrosView from '../views/BezerrosView.vue'
import FazendasView from '../views/FazendasView.vue'
import CategoriasView from '../views/CategoriasView.vue'
import RacasView from '../views/RacasView.vue'
import UsuariosView from '../views/UsuariosView.vue'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import IntroView from '../views/IntroView.vue'

const routes = [
  {
    path: '/',
    name: 'intro',
    component: IntroView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/novo-animal',
    name: 'adicionar animal',
    component: CadastroView
  },
  {
    path: '/nova-fazenda',
    name: 'adicionar fazenda',
    component: CadastroFazendaView
  },
  {
    path: '/nova-categoria',
    name: 'adicionar categoria',
    component: CadastroCategoriaView
  },
  {
    path: '/nova-raca',
    name: 'adicionar raça',
    component: CadastroRacaView
  },
  {
    path: '/novo-usuario',
    name: 'adicionar usuário',
    component: CadastroUsuarioView
  },
  {
    path: '/dashboard-animais',
    name: 'dashboard animais',
    component: BezerrosView
  },
  {
    path: '/dashboard-fazendas',
    name: 'dashboard fazendas',
    component: FazendasView
  },
  {
    path: '/dashboard-categorias',
    name: 'dashboard categorias',
    component: CategoriasView
  },
  {
    path: '/dashboard-racas',
    name: 'dashboard racas',
    component: RacasView
  },
  {
    path: '/dashboard-usuarios',
    name: 'dashboard usuarios',
    component: UsuariosView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes

})

export default router
